.overviewCenters-wrapper {
    display: flex;
    justify-content: center;
    height: 100%;
    margin: 0;
}

.overviewCenters-header-text {
    display: flex;
    flex-direction: column;
    width: 95%;
    padding: 1%;
}

.overviewCenters-header {
    font-weight: bold;
    font-size: 24px;
    color: #2A57A5;
    padding-bottom: 1%;
}

.overviewCenters-grid {
    align-self: center;
    height: 400px;
    width: 100%;
}
@media (min-width: 1265px) {
    .overviewCenters-grid {
        width: 50%;
    }
}