.inputText-general {
    width: 100%;
    padding-bottom: 3%;
}

.inputText-other {
    display: flex;
    align-self: flex-start;
    width: 100%;
    padding-top: 3%;
}

.formfield {
    width: 90%;
    padding-bottom: 3%;
}

.inputMask-main-header {
    font-weight: bold;
    font-size: 24px;
    color: #2A57A5;
}

@media (max-width: 1265px) {
    .inputMask-main-header {
        padding-bottom: 3%;
        padding-top: 10%;
    }
}

.treatmentTimesHeading {
    font-weight: bold;
    font-size: 16px;
    padding-top: 3%;
    padding-bottom: 3%;
    color: #2A57A5;
    align-self: flex-start;
}

@media (max-width: 1265px) {
    .treatmentTimesHeading {
        padding-top: 10%;
    }
}

.inputMask-subheading {
    font-weight: bold;
    font-size: 18px;
    padding-top: 3%;
    padding-bottom: 3%;
    color: #2A57A5;
}

@media (max-width: 1265px) {
    .inputMask-subheading {
        width: 130%;
        text-align: start;
    }
}