.landingpage-wrapper {
    display: flex;
    justify-content: center;
    height: 100%;
    margin: 0;
    padding-bottom: 2%;
}

@media (max-width: 1265px) {
    .landingpage-wrapper {
        flex-direction: column;
    }
}

.landingpage-wrapper img{
    width: 30%;
}

.landingpage-header-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 95%;
    padding: 1%;
}
.landingpage-header-text-image {
    display: flex;
    flex-direction: row;
    width: 95%;
    padding: 1%;
}
.landingpage-header {
    font-weight: bold;
    font-size: 24px;
    color: #2A57A5;
    padding-bottom: 1%;
}

.landingpage-text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-right: 5%;
    padding-left: 5%;
}

.landingpage-logo {
    display: flex;
    align-self: center;
    margin-top: 5%;
}

@media (min-width: 1265px) {
    .landingpage-logo {
        display: none;
    }
}

@media (max-width: 1265px) {
    img {
        display: none;
    }
}
