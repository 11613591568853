.header-background {
    background: #F5C800;
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1%;
}

@media (max-width: 1265px) {
    .header-background {
        flex-direction: column;
        padding-top: 3%;
    }
}

.header-background img {
    display: flex;
    justify-content: center;
    width: 10%;
    margin: 2%;
    max-width: 100%;
}

@media (max-width: 1265px) {
    .header-background img {
        display: none;
    }
}

.header-title {
    align-self: center;
    font-weight: bold;
    color: #2A57A5;
    font-size: 34px;
    margin-left: 10%;
}

@media (max-width: 1265px) {
    .header-title {
        margin-left: 0;
    }
}

.header-hyperlinks-button {
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-right: 1%;
    margin-top: 1%;
}

.header-button {
    align-items: center;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    margin-bottom: 15%;
    margin-top: 5%;
}

@media (max-width: 1265px) {
    .header-button {
        align-self: center;
        margin-bottom: 3%;
    }
}

