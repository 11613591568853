.labvalues-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.clinicalInformation-score-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.score-value-container {
    display: flex;
    justify-content: flex-start;
}

.labvalue-date-label {
    display: flex;
    min-width: 190px;
}

.score-date-label {
    display: flex;
    min-width: 390px;
}

@media (max-width: 1265px) {
    .score-date-label,.labvalue-date-label {
        padding-bottom: 3%;
    }
}

.date-input {
    max-width: 180px;
}

.clinicalEvaluation-labvalues {
    display: flex;
    flex-direction: column;
    align-self: center;
}

.labvalue-id {
    color: #2A57A5;
    padding-bottom: 3%;
}

@media (max-width: 1265px) {
    .labvalue-id {
        padding-bottom: 5%;
    }
}

.date-info {
    color: #2A57A5;
    font-size: 12px;
    padding-bottom: 3%;
    text-align: left;
}

.unitCalculator {
    color: #2A57A5;
    padding-bottom: 3%;
}

.date-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 3%;
}

@media (max-width: 1265px) {
    .date-container {
        flex-direction: column;
        align-items: flex-start;
    }
}

.symptomsAfterCovid-value {
    display: flex;
    justify-content: flex-start;
    padding-left: 10%;

}

.symptomsAfterCovid-group-container {
    display: flex;
    flex-direction: column;
}

.clinicalEvaluation-labid-date {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
}

.clinicalEvaluation-inputMask-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

@media (max-width: 1265px) {
    .clinicalEvaluation-inputMask-container {
        margin-top: 5%;
    }
}