.user {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    padding-top: 2%;
}

.user-header-text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fab {
    margin: 0;
    top: auto;
    right: 20px;
    bottom: 20px;
    left: auto;
    position: fixed;
}