
.inputNumber {
    display: flex;
    flex-direction: row;
    padding-bottom: 2%;
    align-items: center;
}

@media (max-width: 1265px) {
    .inputNumber {
        flex-direction: column;
        align-items: flex-start;
        padding-top: 5%;
    }
}

.inputNumberUnit {
    flex: 0.3;
    min-width: 50px;
    align-self: center;
}

.inputNumber-textfield-unit {
    display: flex;
    flex-direction: row;
}

.inputNumberLabel {
    display: flex;
}

@media (max-width: 1265px) {
    .inputNumberLabel {
        max-width: 500px;
    }
}

.inputNumberTextfield {
    flex: 0.65;
}