.singleInformation-inputMask-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

@media (max-width: 1265px) {
    .singleInformation-inputMask-container {
        margin-top: 5%;
    }
}