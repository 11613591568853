.legalNotice-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
}

.legalNotice {
    width: 50%;
}

.legalNotice-header {
    font-weight: bold;
    font-size: 24px;
    color: #2A57A5;
    padding-bottom: 1%;
}

.legalNotice-subheader {
    color: #2A57A5;
}

.company {
    display: flex;
    flex-direction: column;
}

.legalNotice-text-padding {
    padding-top: 2%;
    padding-bottom: 2%;
}