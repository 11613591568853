.basicData {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
}

.basicData-header-text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.basicData-id-date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    font-weight: bold;
    font-size: 18px;
    padding-top: 3%;
    padding-bottom: 3%;
    color: #2A57A5;
}

@media (max-width: 1265px) {
    .basicData-id-date {
        font-size: 16px;
    }
}

.basicData-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    padding-top: 3%;
    padding-bottom: 3%;
}

.basicData-center-id {
    display: flex;
    align-self: flex-end;
    font-weight: bold;
    padding-right: 2.5%;
    padding-top: 1%;
    font-size: 18px;
    color: #2A57A5;
}

.fab {
    margin: 0;
    top: auto;
    right: 20px;
    bottom: 20px;
    left: auto;
    position: fixed;
}